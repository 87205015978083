$(function() {

    const $menuMain = $('#menu-main'),
        menuShow = $('#page-top').outerHeight() * .5;
    $(window)
        .scroll(function(){
            const st = $(this).scrollTop();
            if(st >= menuShow - 200) {
                $menuMain.addClass('fixed');
            }else {
                $menuMain.removeClass('fixed');
            }
            if(st >= menuShow) {
                $menuMain.addClass('show');
            }else {
                $menuMain.removeClass('show');
            }
        });

    const $anchor = $('[data-role="scroll-anchor"]');
    if($anchor.length) {
        $anchor.ptScroll({
            offset:60
        });
    }

    $('.schedule__header:not(.archive)').append(
        $('<span/>').addClass('score-label').append(
            $('<span/>').addClass('score-label__num').text('+200')
        ).append(
            $('<span/>').addClass('score-label__hint').text('баллов')
        )
    );

    // modal popups
    const $modal = $('#modal'),
        $popupShow = $('[data-role="show-popup"]');

    function hideModal() {
        $('html,body').css({
            'overflow': 'auto'
        });
        $modal.fadeOut(300, function() {
            $('#video', $modal).html(null);
            $('#popup', $modal).removeClass('active');
            $('.popup', $modal).hide();
        });
        return false;
    }

    $popupShow
        .on('click', function() {
            const target = $(this).data('target'),
                id = $(this).data('id') || false,
                $popup = $('#' + target);
            if ($popup.length) {
                if (target === 'video' && id) {
                    $popup.html(
                        $('<iframe/>').attr({
                            width:           820,
                            height:          460,
                            src:             'https://www.youtube.com/embed/' + id,
                            frameborder:     0,
                            allow:           'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
                            allowfullscreen: true
                        })
                    );
                }
                $popup.show();
                $('html,body').css({
                    'overflow': 'hidden'
                });
                $modal.fadeIn(300);
                $('#popup', $modal).addClass('active');
            }
            return false;
        });
    $modal
        .on('click', '.btn_close', hideModal)
        .on('click', '#popup', function(e) {
            e.stopPropagation();
        })
        .on('click', hideModal);




    console.log('Document ready');
});
